<template>

  <b-row>

    <!-- Left -->
    <b-col
      cols="12"
      lg="9"
    >

      <b-alert
        :show="userData.role === 'admin' && formUpdateStatus === 1"
        variant="warning"
        style="padding: 0.71rem 1rem; border: 1px solid transparent;"
      >
        Please see the following list of requested changes.
        <ul
          class="d-flex flex-column flex-wrap mt-50"
          style="max-height: 300px;"
        >
          <li
            v-for="(value, index) in formUpdateChanges"
            :key="index"
          >
            {{ value }}
          </li>
        </ul>
        <hr>
        <div class="text-right">
          <b-button
            variant="danger"
            class="mr-1"
            @click="denyFormUpdate"
          >
            Deny
          </b-button>
          <b-button
            variant="secondary"
            @click="approveFormUpdate"
          >
            Approve
          </b-button>
        </div>
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 1"
        variant="warning"
        style="padding: 0.71rem 1rem; border: 1px solid transparent;"
      >
        You still have pending profile update requests. Please wait for the Admin to review them before making any other changes.
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 2"
        variant="primary"
        dismissible
        fade
        style="padding: 0.71rem 1rem; cursor: pointer; border: 1px solid transparent;"
        @dismissed="dismissFormUpdate"
      >
        The HR/Admin has approved the changes you made.
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 3"
        variant="danger"
        dismissible
        fade
        style="border: 1px solid transparent;"
        @dismissed="dismissFormUpdate"
      >
        <div
          style="padding: 0.71rem 1rem; cursor: pointer;"
          @click="dismissFormUpdate"
        >
          The HR/Admin has denied the changes you made.
        </div>
      </b-alert>

      <b-overlay
        :show="userData.role === 'agent' && formUpdateStatus > 0"
        spinner-type="false"
        opacity="0.25"
        blur
        rounded
      >

        <!-- Card -->
        <b-card>

          <b-row class="hr-form">

            <b-col
              cols="12"
            >

              <b-card-title>
                Criminal Record Statement
                <br>
                <small>Long-Term Care Ombudsman Program</small>
              </b-card-title>

              <em>Persons submitting an application to participate in the Long-Term Care Ombudsman Program must be fingerprinted and disclose any conviction(s). A conviction is any plea of guilty or nolo contendere (no contest) or a verdict of guilty. The fingerprints will be used to obtain a copy of any criminal history you may have.</em>
              <hr>

              <validation-observer ref="simpleRules">
                <b-form>

                  <b-row>
                    <b-col
                      cols="12"
                    >

                      <b-form-group
                        label="* Have you ever been convicted of a crime in California?"
                        label-for="crime-cal"
                      >
                        <label
                          class="sr-only"
                          for="crime-cal"
                        >
                          * Have you ever been convicted of a crime in California?
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Crime in California"
                          rules="required"
                        >
                          <b-form-radio-group
                            ref="crimeCal"
                            v-model="applicantSection.crimeCal"
                            name="crimeCal"
                            title="Crime in California"
                          >
                            <b-form-radio
                              v-for="option in radioYN"
                              :key="option.text"
                              :value="option.text"
                              :state="errors.length > 0 ? false : null"
                            >
                              {{ option.text }}
                            </b-form-radio>
                          </b-form-radio-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                      <em>You need not disclose any marijuana-related offenses covered by the marijuana reform legislation codified at Health and Safety Code sections 11361.5 and 11361.7.</em>

                      <b-form-group
                        label="* Have you ever been convicted of a crime from another state, federal court, military or jurisdiction outside of U.S.?"
                        label-for="crime-state"
                        class="mt-1"
                      >
                        <label
                          class="sr-only"
                          for="crime-state"
                        >
                          * Have you ever been convicted of a crime from another state, federal court, military or jurisdiction outside of U.S.?
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Crime from another State"
                          rules="required"
                        >
                          <b-form-radio-group
                            ref="crimeState"
                            v-model="applicantSection.crimeState"
                            name="crimeState"
                            title="Crime from another State"
                          >
                            <b-form-radio
                              v-for="option in radioYN"
                              :key="option.text"
                              :value="option.text"
                              :state="errors.length > 0 ? false : null"
                            >
                              {{ option.text }}
                            </b-form-radio>
                          </b-form-radio-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                      <p class="p-underline">
                        Criminal convictions from another State or Federal court are considered the same as criminal convictions in California.
                      </p>

                      If you answer YES, give details on the back of this page indicating the nature and circumstances of each crime and the date and the location in which each crime occurred.<br><br>

                      You must disclose convictions, including reckless and drunk driving convictions even if:

                      <ol>
                        <li>It happened a long time ago;</li>
                        <li>It was only a misdemeanor;</li>
                        <li>You didn’t have to go to court (your attorney went for you);</li>
                        <li>You had no jail time or the sentence was only a fine or probation;</li>
                        <li>You received a certificate of rehabilitation;</li>
                        <li>The conviction was later dismissed, set aside or the sentence was suspended.</li>
                      </ol>

                      <strong>NOTE:</strong> IF THE CRIMINAL BACKGROUND CHECK REVEALS ANY CONVICTION(S) THAT YOU DID NOT DISCLOSE ON THIS FORM, YOUR FAILURE TO DISCLOSE THE CONVICTION(S) WILL RESULT IN AN EXEMPTION DENIAL.
                    </b-col>
                  </b-row>

                  <br><strong>I declare under penalty of perjury under the laws of the State of California that I have read and understand the information contained in this affidavit and that my responses and any accompanying attachments are true and correct.</strong>

                  <b-row
                    class="mt-1"
                  >
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Your Name"
                        label-for="name"
                      >
                        <label
                          class="sr-only"
                          for="name"
                        >
                          * Your Name
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Your Name"
                          rules="required"
                        >
                          <b-form-input
                            id="name"
                            ref="name"
                            v-model="applicantSection.name"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Your Name"
                            title="Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Your Address"
                        label-for="address"
                      >
                        <label
                          class="sr-only"
                          for="address"
                        >
                          * Your Address
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Your Address"
                          rules="required"
                        >
                          <b-form-input
                            id="address"
                            ref="address"
                            v-model="applicantSection.address"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Your Address"
                            title="Address"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="2"
                    >

                      <b-form-group
                        label="* City"
                        label-for="city"
                      >
                        <label
                          class="sr-only"
                          for="city"
                        >
                          * City
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="City"
                          rules="required"
                        >
                          <b-form-input
                            id="city"
                            ref="city"
                            v-model="applicantSection.city"
                            :state="errors.length > 0 ? false : null"
                            placeholder="City"
                            title="City"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="2"
                    >

                      <b-form-group
                        label="* Zip"
                        label-for="zip"
                      >
                        <label
                          class="sr-only"
                          for="zip"
                        >
                          * Zip
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Zip"
                          rules="required"
                        >
                          <b-form-input
                            id="zip"
                            ref="zip"
                            v-model="applicantSection.zip"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Zip"
                            title="Zip"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Social Security Number"
                        label-for="ssn"
                      >
                        <label
                          class="sr-only"
                          for="ssn"
                        >
                          * Social Security Number
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Social Security Number"
                          rules="required"
                        >
                          <b-form-input
                            id="ssn"
                            ref="ssn"
                            v-model="applicantSection.ssn"
                            v-mask="['###-##-####']"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Social Security Number"
                            title="Social Security Number"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Date of Birth"
                        rules="required"
                      >
                        <b-form-group
                          label="* Date of Birth"
                          label-for="date-birth"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="date-birth"
                          >
                            * Date of Birth
                          </label>
                          <flat-pickr
                            id="date-birth"
                            ref="dateBirth"
                            v-model="applicantSection.dateBirth"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Date of Birth"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* DMV License Number"
                        label-for="dmv-num"
                      >
                        <label
                          class="sr-only"
                          for="dmv-num"
                        >
                          * DMV License Number
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="DMV License Number"
                          rules="required"
                        >
                          <b-form-input
                            id="dmv-num"
                            ref="dmvNum"
                            v-model="applicantSection.dmvNum"
                            :state="errors.length > 0 ? false : null"
                            placeholder="DMV License Number"
                            title="DMV License Number"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Signature"
                        label-for="applicant-signature"
                      >
                        <label
                          class="sr-only"
                          for="applicant-signature"
                        >
                          * Signature
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Signature1"
                          rules="required"
                        >
                          <vue-signature-pad
                            ref="signaturePad1"
                            v-model="applicantSection.signature"
                            :state="errors.length > 0 ? false : null"
                            width="100%"
                            height="150px"
                            :options="{ onBegin, onEnd }"
                            class="form-control"
                            :class="{ 'is-invalid' : errors.length > 0 && applicantSection.signature === '' }"
                            title="Signature"
                          />

                          <b-row>

                            <b-col>
                              <small
                                v-if="applicantSection.signature === ''"
                                class="text-danger"
                              >{{ errors[0] }}
                              </small>
                            </b-col>

                            <b-col
                              class="text-right mt-1"
                            >
                              <!-- Button Undo -->
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-dark"
                                class="mr-1"
                                @click="undoSignature"
                              >
                                Undo
                              </b-button>

                              <!-- Button Clear -->
                              <b-button
                                variant="outline-danger"
                                @click="clearSignature"
                              >
                                Clear
                              </b-button>
                            </b-col>
                          </b-row>

                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Date"
                        rules="required"
                      >
                        <b-form-group
                          label="* Date"
                          label-for="date"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="date"
                          >
                            * Date
                          </label>
                          <flat-pickr
                            id="date"
                            ref="dateSigned"
                            v-model="applicantSection.dateSigned"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Date Signed"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>
                  </b-row>

                  <p class="p-underline mt-1">
                    <strong>I. Instructions to Respondents:</strong>
                  </p>
                  If you have been convicted of a crime in California, another state or in federal court, provide the following information:<br><br>
                  <em>(You need not disclose any marijuana-related offenses covered by the marijuana reform legislation codified at Health and Safety Code sections 11361.5 and 11361.7.)</em>

                  <b-row>
                    <b-col
                      cols="12"
                    >

                      <b-form-group
                        label="* What was the offense?"
                        label-for="offense"
                        class="mt-1"
                      >
                        <label
                          class="sr-only"
                          for="offense"
                        >
                          * What was the offense?
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="What was the offense?"
                          rules="required"
                        >
                          <b-form-textarea
                            id="offense"
                            ref="offense"
                            v-model="respondentSection.offense"
                            :state="errors.length > 0 ? false : null"
                            placeholder="What was the offense?"
                            rows="3"
                            max-rows="6"
                            title="What was the offense?"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                      <b-form-group
                        label="* In which state and city did you commit the offense?"
                        label-for="commit-offense"
                      >
                        <label
                          class="sr-only"
                          for="commit-offense"
                        >
                          * In which state and city did you commit the offense?
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="In which state and city did you commit the offense?"
                          rules="required"
                        >
                          <b-form-textarea
                            id="commit-offense"
                            ref="commitOffense"
                            v-model="respondentSection.commitOffense"
                            :state="errors.length > 0 ? false : null"
                            placeholder="In which state and city did you commit the offense?"
                            rows="3"
                            max-rows="6"
                            title="In which state and city did you commit the offense?"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                      <b-form-group
                        label="* When did this occur?"
                        label-for="occur"
                      >
                        <label
                          class="sr-only"
                          for="occur"
                        >
                          * When did this occur?
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="When did this occur?"
                          rules="required"
                        >
                          <b-form-textarea
                            id="occur"
                            ref="occur"
                            v-model="respondentSection.occur"
                            :state="errors.length > 0 ? false : null"
                            placeholder="When did this occur?"
                            rows="3"
                            max-rows="6"
                            title="When did this occur?"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                      <b-form-group
                        label="* Tell us what happened."
                        label-for="happened"
                      >
                        <label
                          class="sr-only"
                          for="happened"
                        >
                          * Tell us what happened.
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Tell us what happened"
                          rules="required"
                        >
                          <b-form-textarea
                            id="happened"
                            ref="happened"
                            v-model="respondentSection.happened"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Tell us what happened."
                            rows="3"
                            max-rows="6"
                            title="Tell us what happened."
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  I certify under penalty of perjury that the above information is true and correct to the best of my knowledge.

                  <b-row
                    class="mt-1"
                  >
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Signature"
                        label-for="respondent-signature"
                      >
                        <label
                          class="sr-only"
                          for="respondent-signature"
                        >
                          * Signature
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Signature2"
                          rules="required"
                        >
                          <vue-signature-pad
                            ref="signaturePad2"
                            v-model="respondentSection.signature"
                            :state="errors.length > 0 ? false : null"
                            width="100%"
                            height="150px"
                            :options="{ onBegin, onEnd }"
                            class="form-control"
                            :class="{ 'is-invalid' : errors.length > 0 && respondentSection.signature === ''}"
                            title="Respondent Signature"
                          />

                          <b-row>

                            <b-col>
                              <small
                                v-if="respondentSection.signature === ''"
                                class="text-danger"
                              >{{ errors[0] }}
                              </small>
                            </b-col>

                            <b-col
                              class="text-right mt-1"
                            >
                              <!-- Button Undo -->
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-dark"
                                class="mr-1"
                                @click="undoSignature2"
                              >
                                Undo
                              </b-button>

                              <!-- Button Clear -->
                              <b-button
                                variant="outline-danger"
                                @click="clearSignature2"
                              >
                                Clear
                              </b-button>
                            </b-col>
                          </b-row>

                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Respondent Date"
                        rules="required"
                      >
                        <b-form-group
                          label="* Date"
                          label-for="respondent-date"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="respondent-date"
                          >
                            * Date
                          </label>
                          <flat-pickr
                            id="respondent-date"
                            ref="respondentDateSigned"
                            v-model="respondentSection.dateSigned"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Respondent Date Signed"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>
                  </b-row>

                  <p class="p-underline mt-1">
                    <strong>II. Instructions to the Office of the Long-Term Care Ombudsman:</strong>
                  </p>
                  If the person discloses a criminal conviction, review the person’s statement. Maintain this form in your personnel file and send a copy to the Caregiver Background Check Bureau, 744 P Street, MS 9-15-62, Sacramento, CA 95814.<br><br>

                  <p class="text-center">
                    <strong>PRIVACY STATEMENT</strong>
                  </p>

                  Pursuant to the Federal Privacy Act (P.L. 93-579) and the Information Practices Act of 1977 (Civil Code section 1798 et seq.), notice is given for the request of the Social Security Number (SSN) on this form. The California Department of Justice uses a person’s SSN as an identifying number. The requested SSN is voluntary. Failure to provide the SSN may delay the processing of this form and the criminal record check.<br><br>

                  In order to be licensed, work at, or be present at, a licensed facility, the law requires that you complete a criminal background check. (Health and Safety Code sections 1522, 1568.09, 1569.17 and 1596.871) The Department will create a file concerning your criminal background check that will contain certain documents, including information that you provide. You have the right to access certain records containing your personal information maintained by the Department (Civil Code section 1798 et seq.). Under the California Public Records Act, the Department may have to provide copies of some of the records in the file to members of the public who ask for them, including newspaper and television reporters.<br><br>

                  NOTE: IMPORTANT INFORMATION<br>
                  The Department is required to tell people who ask, including the press, if some one in a licensed facility has a criminal record exemption. The Department must also tell people who ask, the name of a licensed facility that has a licensee, employee, resident, or other person with a criminal record exemption.<br><br>

                  If you have any questions about this form, please contact your local licensing regional office.
                  <!-- Button Submit -->
                  <b-button
                    variant="primary"
                    type="submit"
                    class="mr-1 d-none"
                    @click.prevent="validationForm"
                  >
                    Submit
                  </b-button>

                </b-form>
              </validation-observer>

            </b-col>
          </b-row>

        </b-card>

      </b-overlay>
    </b-col>

    <!-- Right -->
    <b-col
      cols="12"
      lg="3"
    >

      <!-- Action Buttons -->
      <b-card>

        <!-- Button Preview -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="gradient-main"
          class="mb-75 btn-print-preview"
          block
          @click="$store.dispatch('app-hr15/preview')"
        >
          Print Preview
        </b-button>

        <!-- Button Save -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="secondary"
          block
          :disabled="(userData.role === 'agent' && formUpdateStatus > 0) ? true : isNotSaved"
          @click="validationForm()"
        >
          Save
        </b-button>

        <!-- Button Cancel -->
        <b-button
          v-if="action !== 'preview'"
          variant="outline-dark"
          class="mb-75"
          :to="{ name: (userData.role === 'agent' ? 'caregiverLanding' : 'hr') }"
          block
        >
          Cancel
        </b-button>

        <div class="d-flex">
          <!-- Button Previous -->
          <b-button
            variant="outline-dark"
            class="flex-fill mr-25"
            :to="{ name: 'apps-hr-form14c', params: { action: action }, query: { id: id } }"
          >
            Previous
          </b-button>

          <!-- Button Next -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="secondary"
            class="flex-fill ml-25"
            :to="{ name: 'apps-hr-form16', params: { action: action }, query: { id: id } }"
          >
            Next
          </b-button>
        </div>

        <!-- Spacer -->
        <hr class="mb-50">

        <label for="forms">Form Selection</label>
        <v-select
          id="forms"
          v-model="forms"
          :reduce="(option) => option.name"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="formsOptions"
          :clearable="false"
          :disabled="!id"
          class="hr-forms-select"
          @input="$router.push({ name: forms, params: { action: action }, query: { id: id } })"
        >
          <template #selected-option="{ label }">
            <span class="text-truncate overflow-hidden">
              {{ label }}
            </span>
          </template>
        </v-select>
      </b-card>

    </b-col>
  </b-row>

</template>

<script>
import SockJS from 'sockjs-client'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BOverlay, BAlert, BCard, BCardTitle, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, VBToggle, BFormTextarea, BFormRadioGroup, BFormRadio,
} from 'bootstrap-vue'
import Swal from 'sweetalert2'
import { VueSignaturePad } from 'vue-signature-pad'
import { required } from '@validations'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import { mask } from 'vue-the-mask'

import { getUserData } from '@/auth/utils'

import hrStoreModule from '../hrStoreModule'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    BOverlay,
    BAlert,
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormTextarea,
    BFormRadioGroup,
    BFormRadio,
    VueSignaturePad,

    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    mask,
  },
  setup() {
    const HR_APP_STORE_MODULE_NAME = 'app-hr15'
    // Register module
    if (!store.hasModule(HR_APP_STORE_MODULE_NAME)) store.registerModule(HR_APP_STORE_MODULE_NAME, hrStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HR_APP_STORE_MODULE_NAME)) store.unregisterModule(HR_APP_STORE_MODULE_NAME)
    })
    // const form = ref({})
    if (router.currentRoute.params.action === 'add') {
      // Do something
    }

    const form = ref({})

    const formUpdate = ref({})
    const formUpdateStatus = ref(0)

    const formCopy = ref({})

    const userData = ref(getUserData())

    const formsOptions = [
      { label: 'Basic Information', name: 'apps-hr-form' },
      { label: 'Employment Checklist', name: 'apps-hr-form1' },
      { label: 'Employment Application', name: 'apps-hr-form2' },
      { label: 'Employment Skills List', name: 'apps-hr-form3' },
      { label: 'Work Experiences Checklist', name: 'apps-hr-form4' },
      { label: 'Employment Eligibility Verification', name: 'apps-hr-form5' },
      { label: 'Form W-4', name: 'apps-hr-form6' },
      { label: 'Non-Compete and Non-Solicitation Agreement', name: 'apps-hr-form7' },
      { label: 'Employment Verification Fax', name: 'apps-hr-form8' },
      { label: 'Ref Inquiry Form', name: 'apps-hr-form9' },
      { label: 'Request for Live Scan Service - Community Care Licensing', name: 'apps-hr-form10' },
      { label: 'New Hire Orientation Acknowledgment Form', name: 'apps-hr-form11' },
      { label: 'Employee Tuberculin Skin Test (TST) and Evaluation', name: 'apps-hr-form12' },
      { label: 'Home Care Organization Inspection Checklist', name: 'apps-hr-form13' },
      { label: 'Personnel Record (Admin)', name: 'apps-hr-form14a' },
      { label: 'Personnel Record', name: 'apps-hr-form14b' },
      { label: 'Applicant for Home Care Aide Registration', name: 'apps-hr-form14c' },
      { label: 'Criminal Record Statement', name: 'apps-hr-form15' },
      { label: 'Statement Acknowledging Requirement to Report Suspected Abuse of Dependent Adults and Elders', name: 'apps-hr-form16' },
      { label: 'Registered Home Care Aide Training Log A', name: 'apps-hr-form17a' },
      { label: 'Registered Home Care Aide Training Log B', name: 'apps-hr-form17b' },
      { label: 'Workplace Safety', name: 'apps-hr-form18' },
      { label: 'Current Knowledge', name: 'apps-hr-form19' },
      { label: 'Fact Sheet (Home Care Services - April 2017)', name: 'apps-hr-form20' },
      { label: 'Acknowledgement Form - Fact Sheet (Home Care Services)', name: 'apps-hr-form21' },
      { label: 'Training Completion Certificate', name: 'apps-hr-form22' },
    ]
    const flatPickrConfig = {
      dateFormat: 'Y-m-d',
    }
    const vueSignaturePadOptions = {
      penColor: 'rgb(0, 0, 0)',
    }
    const vueSignaturePadOptions2 = {
      penColor: 'rgb(0, 0, 0)',
    }

    const sock = new SockJS('https://socket.motivit.com:443/echo')
    const sockId = 'coc-n01'

    return {
      form,

      formUpdate,
      formUpdateStatus,

      formCopy,

      userData,

      formsOptions,
      flatPickrConfig,
      vueSignaturePadOptions,
      vueSignaturePadOptions2,

      sock,
      sockId,
    }
  },
  data() {
    return {
      id: this.$route.query.id,
      action: this.$route.params.action,
      isNotSaved: false,
      forms: this.$route.name,
      formUpdateChanges: [],
      required,
      formData: [],
      applicantSection: {
        crimeCal: '',
        crimeState: '',
        name: '',
        address: '',
        city: '',
        zip: '',
        ssn: '',
        dateBirth: '',
        dmvNum: '',
        signature: '',
        dateSigned: '',
      },
      respondentSection: {
        offense: '',
        commitOffense: '',
        occur: '',
        happened: '',
        signature: '',
        dateSigned: '',
      },

      // options
      radioYN: [
        {
          id: 1,
          text: 'Yes',
        },
        {
          id: 2,
          text: 'No',
        },
      ],
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    'formUpdate.data': function () {
      Object.entries(this.formUpdate.data).forEach(([key, val]) => {
        // console.log(key, JSON.stringify(val), JSON.stringify(this.formCopy[key]), JSON.stringify(val) !== JSON.stringify(this.formCopy[key]) && (Number(val) !== Number(this.formCopy[key]) || String(val) !== String(this.formCopy[key])))
        if (JSON.stringify(val) !== JSON.stringify(this.formCopy[key]) && (Number(val) !== Number(this.formCopy[key]) || String(val) !== String(this.formCopy[key]))) {
          Object.entries(val).forEach(([key1, val1]) => {
            // console.log(val1, this.formCopy[key][key1], key1, val1 !== this.formCopy[key][key1])
            if (val1 !== this.formCopy[key][key1]) {
              try {
                let k = key1
                if (key === 'applicantSection') {
                  if (key1 === 'signature') {
                    k = 'signaturePad1'
                  }
                }
                if (key === 'respondentSection') {
                  if (key1 === 'signature') {
                    k = 'signaturePad2'
                  }
                  if (key1 === 'dateSigned') {
                    k = 'respondentDateSigned'
                  }
                }
                console.log(key, key1, k, this.$refs)
                this.formUpdateChanges.push(this.$refs[k].$attrs.title)
              } catch (error) {
                // console.error(error)
              }
            }
          })
        }
        /* if (val !== valCopy) {
          try {
            const keys = Object.keys(this.$refs).filter(k => k.toLowerCase() === key)
            if (key === 'signature') {
              keys.push('empSignature')
            }
            this.formUpdateChanges.push(this.$refs[keys[0]].$attrs.title)
          } catch (error) {
            // console.error(error)
          }
        } */
      })
    },
  },
  created() {
    this.sock.onopen = () => {
      // console.log('Connection established.')
    }

    this.sock.onmessage = event => {
      const data = JSON.parse(event.data)
      if (data.id === this.sockId) {
        this.getSockMsg(data.msg)
      }
    }

    this.sock.onclose = () => {
      // console.warn('Connection closed.')
    }

    if (this.$route.params.action === 'edit' && this.$route.query.id != null) {
      this.fetchCaregiverData()
      this.fetchForm15()
    }
    if (this.$route.params.action === 'add' && this.$route.query.id !== null) {
      this.fetchCaregiverData()
      this.fetchForm15()
    }
  },
  methods: {
    getSockMsg(sockMsg) {
      if (sockMsg === true) {
        // Do something
      }
    },
    sendSockMsg(sockMsg) {
      this.sock.send(JSON.stringify({ id: this.sockId, msg: sockMsg }))
    },
    requestFormUpdate() {
      const self = this

      store
        .dispatch('app-hr15/requestFormUpdate2', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 1

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    denyFormUpdate() {
      const self = this

      store
        .dispatch('app-hr15/denyFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          if (jsonObjectLiterals.data.id) {
            self.form = jsonObjectLiterals.data
          }

          this.applicantSection = jsonObjectLiterals.data.applicantSection
          this.respondentSection = jsonObjectLiterals.data.respondentSection
          this.applicantSection.signature = jsonObjectLiterals.data.applicantSection.signature
          this.applicantSection.ssn = jsonObjectLiterals.data.applicantSection.ssn
          this.respondentSection.signature = jsonObjectLiterals.data.respondentSection.signature
          this.$refs.signaturePad1.clearSignature()
          this.$refs.signaturePad1.fromDataURL(jsonObjectLiterals.data.applicantSection.signature)
          this.$refs.signaturePad2.clearSignature()
          this.$refs.signaturePad2.fromDataURL(jsonObjectLiterals.data.respondentSection.signature)

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    approveFormUpdate() {
      const self = this

      store
        .dispatch('app-hr15/approveFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    dismissFormUpdate() {
      const self = this

      store
        .dispatch('app-hr15/dismissFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    saveForm() {
      const self = this
      const formData = {
        applicantSection: this.applicantSection,
        respondentSection: this.respondentSection,
        cid: this.id,
        id: this.form.id,
      }

      let msg = 'Do you want to save the changes?'
      if (this.action === 'add') msg = 'Do you want to add these data?'
      Swal.fire({
        icon: 'warning',
        title: 'Are you sure?',
        text: msg,
        confirmButtonText: 'Proceed',
        showCancelButton: true,
        reverseButtons: true,
        customClass: {
          confirmButton: 'btn btn-secondary',
          cancelButton: 'btn btn-outline-dark mr-1',
        },
        buttonsStyling: false,
        returnFocus: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.isNotSaved = true
          store
            .dispatch(this.action === 'add' ? 'app-hr15/addForm15' : 'app-hr15/updateForm15', formData)
            .then(response => {
              // eslint-disable-next-line
              const jsonObjectLiterals = response.data.response.data

              this.form.id = jsonObjectLiterals.id

              if (this.userData.role === 'agent' && this.action === 'edit' && jsonObjectLiterals.count > 0) {
                self.requestFormUpdate()
              }

              Swal.fire({
                icon: 'success',
                title: 'Saved!',
                text: 'Saved succesfully.',
                showConfirmButton: false,
                timer: 1500,
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                showConfirmButton: false,
                timer: 1500,
              })
              console.error(error)
            })
            .finally(() => {
              self.isNotSaved = false
            })
        }
      })
    },
    clearSignature() {
      this.$refs.signaturePad1.clearSignature()
    },
    undoSignature() {
      this.$refs.signaturePad1.undoSignature()
    },
    clearSignature2() {
      this.$refs.signaturePad2.clearSignature()
    },
    undoSignature2() {
      this.$refs.signaturePad2.undoSignature()
    },
    onBegin() {
      // Do something
    },
    onEnd() {
      this.saveSignature1()
      this.saveSignature2()
    },
    fetchForm15() {
      store
        .dispatch('app-hr15/fetchForm15', { id: this.id })
        .then(response => {
          const data = response.data.response.data[0]
          const obj = JSON.parse(data.forms)

          this.applicantSection = obj.applicantSection
          this.respondentSection = obj.respondentSection
          this.applicantSection.signature = obj.applicantSection.signature
          this.applicantSection.ssn = obj.applicantSection.ssn
          this.respondentSection.signature = obj.respondentSection.signature
          this.$refs.signaturePad1.clearSignature()
          this.$refs.signaturePad1.fromDataURL(obj.applicantSection.signature)
          this.$refs.signaturePad2.clearSignature()
          this.$refs.signaturePad2.fromDataURL(obj.respondentSection.signature)

          this.form = obj

          this.form.id = JSON.stringify(data.id)

          this.form.cid = JSON.stringify(data.cid)

          if (this.form.cid) {
            this.form.form = 'folder4_form2'

            this.formCopy = JSON.parse(JSON.stringify(this.form))

            store.dispatch('app-hr15/fetchFormUpdate', { form: this.form.form, form_id: this.form.cid })
              .then(response2 => {
                // eslint-disable-next-line
                const jsonObjectLiterals = response2.data.response.data

                // console.log(jsonObjectLiterals.data)

                if (jsonObjectLiterals.data.status) {
                  this.formUpdate = jsonObjectLiterals.data

                  this.formUpdateStatus = this.formUpdate.status
                }
              })
              .catch(error => {
                console.error(error)
              })
          }
        })
        .catch(error => {
          console.error(error)
        })
    },
    fetchCaregiverData() {
      store
        .dispatch('app-hr15/fetchCaregiverData', { id: this.id })
        .then(response => {
          // eslint-disable-next-line
          const obj = response.data.response.data[0]
          // eslint-disable-next-line
          this.applicantSection.address = obj.street
          this.applicantSection.city = obj.citytown
          // eslint-disable-next-line
          this.applicantSection.name = obj.firstname + ' ' + obj.lastname
          this.applicantSection.zip = obj.zipcode
          this.applicantSection.dateBirth = obj.dateofbirth
          this.applicantSection.ssn = obj.ssn
        })
        .catch(error => {
          console.error(error)
        })
    },
    validationForm() {
      /* this.saveSignature1()
      this.saveSignature2() */
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.saveForm()
        } else {
          Swal.fire({
            icon: 'error',
            // title: 'Are you sure?',
            // eslint-disable-next-line
            text: 'Please fill-up all of the required fields.',
            confirmButtonText: 'OK',
            showCancelButton: false,
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-secondary',
              cancelButton: 'btn btn-outline-dark mr-1',
            },
            buttonsStyling: false,
            returnFocus: false,
          })
        }
      })
    },
    saveSignature1() {
      const { isEmpty, data } = this.$refs.signaturePad1.saveSignature()
      this.applicantSection.signature = !isEmpty ? data : ''
      console.log(this.applicantSection.signature)
    },
    saveSignature2() {
      const { isEmpty, data } = this.$refs.signaturePad2.saveSignature()
      this.respondentSection.signature = !isEmpty ? data : ''
      console.log(this.respondentSection.signature)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>
.p-underline {
  text-decoration: underline;
}
</style>

<style lang="scss" scoped>
.hr-forms-select {
  width: 100%;
  min-width: 182px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 135px;
  }
}

@media (max-width: 1199.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 112px;
    }
  }
}

@media (max-width: 991.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 598px;
    }
  }
}

@media (max-width: 767.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 406px;
    }
  }
}

@media (max-width: 575.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 352px;
    }
  }
}
</style>
